import { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import { useParent } from '../../contexts/ParentContext'
import useParentStore from '../../store/useParentStore'
import { ACCOUNT_PATH } from '../../router/paths'
import { SESSION_STORAGE_KEYS } from '../../config/constants'

const isGuestLink = window.top.location.pathname.startsWith('/preview')

const ParentDropdown = ({ open, onOpen, onHide }) => {
  const { parentId, setParentId } = useParent()
  const dropdownRef = useRef(null)
  const parents = useParentStore((state) => state.parents)
  const parentMap = useParentStore((state) => state.parentMap)
  const location = useLocation()
  const { parentId: _parentId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef(null)

  const isDisabled = useMemo(() => {
    const url = location.pathname
    if (url.startsWith('/account/list')) {
      return false
    } else if (url.startsWith('/account/account/order-detail')) {
      return true
    } else if (url.startsWith('/account/account/checkout/')) {
      return true
    } else if (url.startsWith('/account/account')) {
      return false
    } else if (url === '/' || url === ACCOUNT_PATH.shopAll) {
      return false
    }
    return true
  }, [location.pathname])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      onHide()
    } else if (
      dropdownRef.current &&
      dropdownRef.current.contains(event.target) &&
      event.target.id !== 'filter-accounts'
    ) {
      const _parentId = event.target.getAttribute('data-parent_id')
      if (_parentId > 0) setParentId(_parentId)
      onHide()
    }
  }

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.focus()
    }
  }, [open])

  useEffect(() => {
    onHide()
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOpenDropdown = () =>{
    setSearchTerm('')
    onOpen()
    setTimeout(()=>{
      inputRef.current.focus()
    },0)
  }

  /*
   * change parentId if
   * parent dropdown is enabled for this path and
   * set first parent from list of parents
   */

  useEffect(() => {
    const firstParentId = parents[0]?.id
    if (parentId) {
      return
    }
    if (!isDisabled && firstParentId) {
      setParentId(firstParentId)
    }
  }, [parents, isDisabled])

  /*
   * change parentId if
   * url params { _parentId } change and
   * previous and new parentId is different
   */
  useEffect(() => {
    if (parentId == _parentId) {
      return
    }
    if (_parentId) {
      setParentId(_parentId)
    }
  }, [location.pathname])

  /*
   * store parentId in session storage if
   * change
   */
  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.PARENT_ID, String(parentId))
  }, [parentId])

  const handleFilterAccounts = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredParents = parents.filter((parent) =>
    parent.accountName.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <div className={classNames({ 'd-none': isGuestLink })}>
      <Dropdown show={open} className={'custom-dropdown nav-dropdown'} ref={dropdownRef} >
        <Dropdown.Toggle
          variant='link'
          id='dropdown-basic'
          className={'btn list-item text-truncate'}
          disabled={isDisabled}
          onClick={handleOpenDropdown}
          style={{ outline: 'none !important' }}
        >
          <i className='icon-store align-middle'></i>{' '}
          {parentMap[parentId]?.accountName || 'Select Parent'}
          <span className='material-symbols-outlined down-arrow d-block d-lg-none'>
            arrow_drop_down
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className='sub-header-dropdown headerFilter' align='end'>
          <div className='dropdown-list-cntr'>
            <div className='mb-2 pb-1 px-3 accounts-search'>
              <div className='dropdown-header m-0 border-bottom-0'>
                <div className='dropdown-header-inner'>
                  <h5 className='header-text'>Select Account</h5>
                  <button className='cross-btn d-block d-lg-none'>
                    <span className='icon-cross' onClick={onHide}></span>
                  </button>
                </div>
              </div>
              <input
                type='text'
                className='form-control'
                autoComplete='off'
                id='filter-accounts'
                placeholder='Search...'
                onChange={handleFilterAccounts}
                onClick={(e) => e.stopPropagation()} // Stop event from bubbling up
                onMouseDown={(e) => e.stopPropagation()}
                ref={inputRef}
              />
            </div>
            {filteredParents.length > 0 ? (
              filteredParents.map((parent) => (
                <Dropdown.Item
                  key={parent.id}
                  style={{ whiteSpace: 'wrap' }}
                  data-parent_id={parent.id}
                  // onClick={() => {
                  //   setParentId(parent.id)
                  //   onHide()
                  // }}
                >
                  {parent.accountName}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled style={{ whiteSpace: 'wrap', textAlign: 'center' }}>
                No found
              </Dropdown.Item>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ParentDropdown
