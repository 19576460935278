/* eslint-disable max-len */
import { useMemo } from 'react'
import { useMutation, useQueries, useQuery } from 'react-query'
import {
  createSizeScale,
  deleteSizeScaleById,
  fetchAltStyleDetail,
  fetchAltStyles,
  fetchBlankGramentInventory,
  fetchBlankStyleFitbyName,
  fetchColorsByTemplateId,
  fetchDefaultColor,
  fetchInventoryCategory,
  fetchLayers,
  fetchPricesByTemplateId,
  fetchSizeScaleById,
  fetchSizeScales,
  fetchStyleCategory,
  fetchTemplateById,
  fetchTemplateGraphicRuleColors,
  fetchTemplateGraphicRules,
  fetchTemplates,
  updateSizeScaleById,
} from '../services/templateService'

export const useQueryFetchInventoryCategories = () => {
  return useQuery(
    ['inventoryCategories'],
    () => fetchInventoryCategory(),
    {
      enabled: false,
    },
  )
}
export const useQueryFetchStyleCategories = () => {
  return useQuery(
    ['styleCategories'],
    () => fetchStyleCategory(),
    {
      enabled: false,
    },
  )
}
export const useQueryFetchAltStyles = () => {
  return useQuery(
    ['altStyles'],
    () => fetchAltStyles(),
    {
      enabled: false,
    },
  )
}

export const useQueryFetchAltStyleDetail = (fieldName, fieldValue) => {
  return useQuery(
    ['altStyleDetail', fieldName, fieldValue],
    () => fetchAltStyleDetail(fieldName, fieldValue),
    {
      enabled: false,
    },
  )
}
export const useQueryFetchBlankStyleFitbyName = ({ name }) => {
  return useQuery(
    ['fetchBlankStyleFitbyName', name],
    () => fetchBlankStyleFitbyName({ name }),
    {
      enabled: false,
    },
  )
}


export const useQueryFetchTemplates = ({ childId, styles, subStyles }) => {
  return useQuery(
    ['templates', childId, styles, subStyles],
    () => fetchTemplates({ childId, styles, subStyles }),
    {
      enabled: false,
    },
  )
}

export const useQueryFetchTemplateById = ({ templateId }) => {
  return useQuery(['template', templateId], () => fetchTemplateById({ templateId }))
}

export const useColorsByTemplateIdQuery = ({ templateId, category, design }) => {
  return useQuery(['template-colors', templateId, category, design], () =>
    fetchColorsByTemplateId({ templateId, category, design }),
  )
}

export const useColorsByTemplateIdQueries = ({ templateId }) => {
  const queriesFor = [
    { category: 'body', design: 'front' },
    { category: 'body', design: 'back' },
    { category: 'graphic', design: 'front' },
    { category: 'graphic', design: 'back' },
    { category: 'graphic2', design: 'front' },
    { category: 'graphic2', design: 'back' },
  ]
  return useQueries(
    queriesFor.map(({ category, design }) => ({
      queryKey: ['template-colors', templateId, category, design],
      queryFn: () => fetchColorsByTemplateId({ templateId, category, design }),
    })),
  )
}

export const useLayersQuery = ({ childId, templateId }) => {
  return useQuery(['layers', childId, templateId], () => fetchLayers({ childId, templateId }))
}

export const useTemplateDefaultColorQuery = ({ childId, templateId }) => {
  return useQuery(['defaultColors', childId, templateId], () =>
    fetchDefaultColor({ childId, templateId }),
  )
}

export const usePricesByTemplateIdQuery = ({ templateId, childId }) => {
  return useQuery(['prices', templateId], () => fetchPricesByTemplateId({ templateId, childId }))
}

export const useSizeScalesQuery = (parentId, options = {}) => {
  return useQuery(['size-scales', parentId], () => fetchSizeScales({ parentId }), options)
}

export const useSizeScaleByIdQuery = ({ id }) => {
  return useMutation(['size-scales', 'read', id], () => fetchSizeScaleById({ id }))
}

export const useSizeScaleCreateMutation = (options) => {
  return useMutation(createSizeScale, options)
}

export const useSizeScaleUpdateMutation = (options) => {
  return useMutation(updateSizeScaleById, options)
}

export const useSizeScaleDeleteMutation = (options) => {
  return useMutation(deleteSizeScaleById, options)
}

export const useGraphicRuleColorsQuery = () => {
  return useQuery(['graphic-rule-colors'], () =>
    fetchTemplateGraphicRuleColors(),
  )
}

export const useGraphicRuleQuery = (templateId, customerId) => {
  return useQuery(['graphic-rules', templateId, customerId], () =>
    fetchTemplateGraphicRules(templateId, customerId),
  )
}

export const useInventoryQuery = (productId, bodyColorAbbr) => {
  const queryInfo = useQuery(['inventory', productId, bodyColorAbbr], () =>
    fetchBlankGramentInventory(productId, bodyColorAbbr),
  )
  return {
    ...queryInfo,
    data: useMemo(() => {
      if (bodyColorAbbr) {
        return Object.values(queryInfo.data?.[bodyColorAbbr] || {}).reduce((a, c) => {
          a[c.size] = c?.qtyInventory < 0 ? 0 : c?.qtyInventory
          return a
        }, {})
      }
      return queryInfo.data
    }, [queryInfo.data])
  }

}
